.rc-tooltip-placement-bottomRight .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 1.2%;
}

.rc-tooltip-inner {
  padding: 24px;
}

.fmla-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-bottom-color: var(--color-black);
}

.fmla-tooltip.rc-tooltip {
  z-index: 100000;
  padding: 12px;
  background-color: transparent;

  .rc-tooltip-content {
    background-color: transparent;
  }

  .rc-tooltip-arrow {
    border-top-color: var(--color-black);
    padding-bottom: 12px;
  }

  .rc-tooltip-inner {
    background: var(--color-black);
    color: var(--color-grey-1);
    font-family: var(--t-font-nunito);
    font-weight: var(--t-font-weight-medium);
    font-size: var(--type-body-xs12);
    line-height: 18px;
    min-height: 0;

    padding: 4px 8px;
    border: none;
  }
}
