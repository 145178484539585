@import '~constants/colors';

.rc-collapse-motion {
  transition: height 0.3s, opacity 0.3s;
}
.rc-collapse-content-hidden {
  display: none;
}
.rc-collapse {
  background-color: var(--color-white);
  border-radius: 3px;
}
.rc-collapse > .rc-collapse-item {
  background: var(--color-white);

  /* Grey/D3D7DB */
  border: 1px solid $light;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(112, 144, 176, 0.2);
  //border-radius: 8px;
  // making 3px x2 so collapsed item has edges
  border-radius: 3px 3px;
  margin-bottom: 8px;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 10px 12px;
  color: $black;
  cursor: pointer;
  background-color: var(--color-white);
  width: 100%;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 16px 0 auto;
}
.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only {
  cursor: default;
}
.rc-collapse > .rc-collapse-item .rc-collapse-header-collapsible-only .rc-collapse-header-text {
  cursor: pointer;
}
.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: #999;
  background-color: #f3f3f3;
}
.rc-collapse-content {
  overflow: hidden;
  color: $black;
  padding: 0 16px;
  background-color: var(--color-white);
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 16px;
  margin-bottom: 16px;
}
.rc-collapse-item:last-child > .rc-collapse-content {
  border-radius: 0 0 3px 3px;
}

// Since we provide our own icons not needed
// .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
//   display: inline-block;
//   content: '\20';
//   width: 0;
//   height: 0;
//   font-size: 0;
//   line-height: 0;
//   border-top: 3px solid transparent;
//   border-bottom: 3px solid transparent;
//   border-left: 4px solid #666;
//   vertical-align: middle;
//   margin-right: 8px;
// }
// .rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
//   position: relative;
//   top: 2px;
//   border-left: 3px solid transparent;
//   border-right: 3px solid transparent;
//   border-top: 4px solid #666;
//   margin-right: 6px;
// }
.rc-collapse > .rc-collapse-item-active > .rc-collapse-content-active,
.rc-collapse > .rc-collapse-item-active > .rc-collapse-header {
  background-color: #f3f3f352;
}

// Styles specific for the leave flyout for HR
.leave-accordion {
  .rc-collapse-item > .rc-collapse-header {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 0 8px 0;
    color: var(--color-grey-3);
    font-size: var(--type-body-base16);
    font-weight: var(--t-font-weight-medium);
    line-height: 24px;

    border-bottom: 1px solid var(--color-grey-2) !important;
  }

  .rc-collapse > .rc-collapse-item {
    margin-bottom: 24px;
  }

  .rc-collapse > .rc-collapse-item-active > .rc-collapse-content-active {
    width: 100%;
    padding: 0;
  }

  .rc-collapse > .rc-collapse-item-active > .rc-collapse-content-active > .rc-collapse-content-box {
    margin-top: 0;
  }

  .rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
    cursor: auto;
    flex-direction: row;
    justify-content: flex-start;

    svg {
      display: none;
    }
  }
}

// Styles specific for the advanced search
.search-container {
  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    padding: 0;
  }

  .rc-collapse > .rc-collapse-item {
    border-top: none;
    border-bottom: none;
  }

  .rc-collapse > .rc-collapse-item-active > .rc-collapse-content-active,
  .rc-collapse > .rc-collapse-item-active > .rc-collapse-header {
    background-color: var(--color-white);
  }

  .rc-collapse-content {
    padding: 0px 24px;
  }

  .rc-collapse-content > .rc-collapse-content-box {
    margin-bottom: 0px;
  }
}
