@import '~constants/colors';

$borderColor: $dividerColor;

.react-calendar-timeline * {
  box-sizing: border-box;
}

.react-calendar-timeline {
  border-bottom: 0px;
}

.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: hidden;
  touch-action: none;
}

.react-calendar-timeline .rct-item {
  z-index: 88;
  box-shadow: 1px 1px 3px #9998;

  &:hover {
    box-shadow: 1px 1px 3px #cccccccc;
  }
}

.react-calendar-timeline .rct-item .rct-item-content {
  // Items have a fixed height so don't alter it with paddings/margins
  position: sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  padding: 0 8px;
  height: 100%;
}

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  background: transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid $borderColor;
  z-index: 30;
}

// If we want to style weekends differently
// .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
// .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
//   background: rgba(250, 246, 225, 0.5);
// }

.react-calendar-timeline .rct-horizontal-lines {
  user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  box-sizing: border-box;
  z-index: 40;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: transparent;
}

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  background: #2196f3;
  z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: var(--type-body-s14);
  background-color: #f0f0f0;
  border-left: 1px solid $borderColor;
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  color: #fff;
}

.react-calendar-timeline .rct-header-root {
  background: transparent;
}

// Top headers container
// .react-calendar-timeline .rct-calendar-header {
//   border-left: 1px solid $borderColor;
// }
