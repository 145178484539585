@import 'react-big-calendar/lib/sass/styles';
@import '~constants/typography';
@import '~constants/colors';
@import '~constants/breakpoints';

.rbc-calendar {
  @media screen and (max-width: $md) {
    height: 350px !important;
  }
}

.rbc-day-bg.rbc-today {
  background: var(--color-white);
}

.rbc-day-bg.leave-day {
  background: $lightRed;
}
.rbc-day-bg.non-working-day {
  background: $light;
}
.rbc-day-bg.error-day {
  background: $accentLight;
}
.rbc-day-bg.available-day {
  cursor: pointer;
  background: white;
}
.rbc-header {
  padding: 7px;
  text-align: left;
  > span {
    @include font-b1-regular;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.rbc-row-content {
  height: 100%;
}
.rbc-date-cell {
  text-align: left;
  padding: var(--spacing-three-eighth);
  height: 100%;
}

.rbc-date-cell > span {
  @include font-b1-regular;
  color: var(--color-grey-4);
}

.rbc-day-bg {
  box-shadow: none;
  border-radius: 0;
  border: 1px solid var(--color-grey-medium);
  border-top: none;
}

.rbc-date-cell.rbc-off-range > span {
  color: var(--color-grey-medium);
}

.rbc-row.rbc-month-header {
  border: 1px solid var(--color-grey-medium);
  border-bottom: none;
  border-top: none;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: none;
}

.rbc-month-view {
  border: 1px solid var(--color-grey-medium);
  border-left: none;
  border-right: none;
  > .rbc-month-row:last-child {
    .rbc-day-bg {
      border-bottom: none;
    }
  }
}

.rbc-month-row {
  border: none;
}

.rbc-month-row + .rbc-month-row {
  border: none;
}

.rbc-row-segment {
  padding: 0;
}
.rbc-event {
  border-radius: 0;
  padding: 0;
  background-color: var(--color-primary-peacock);
  margin-bottom: var(--spacing-eighth);
}
.rbc-event-content {
  padding: var(--spacing-quarter) var(--spacing-half);
}
.rbc-event.rbc-selected {
  background-color: var(--color-primary-peacock);
}

// Style overrides specific to the Time Off Calendar
.timeOffCalendar {
  .rbc-day-bg.approved {
    background-color: var(--color-secondary-grass-light);
  }
  .rbc-day-bg.pending {
    background-color: var(--color-secondary-pumpkin-light);
  }
  .rbc-day-bg.pending-deletion {
    background-color: var(--color-primary-coral-light);
  }
  .rbc-day-bg.blocked {
    background-color: var(--color-grey-1);
  }
  .rbc-day-bg.rbc-off-range-bg {
    background-color: var(--color-white);
  }
  .rbc-date-cell.rbc-off-range {
    .displayCell {
      span.day {
        color: var(--color-grey-medium);
      }
    }
  }
}
