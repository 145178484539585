:root {
  --t-font-nunito: 'Nunito';
  --t-font-poppins: 'Poppins';
  --t-font-size-primary: 1rem; /* 16px */
  --t-font-size-small: 0.9rem; /* 14.4px */
  --t-font-line-primary: 1.5rem; /* 24px */
  --t-font-line-height-base: 2.25rem; /* 36px */
  --t-font-weight-regular: 400;
  --t-font-weight-medium-light: 500;
  --t-font-weight-medium: 700;
  --t-spacing-xxlarge: 40px;
  --t-spacing-xlarge: 36px;
  --t-spacing-large: 24px;
  --t-spacing-regular: 16px;
  --t-spacing-medium: 12px;
  --t-spacing-small: 8px;

  /* Color
     ================================================================= */

  /* Grey scale in descending saturation */
  --color-black: #212429;
  --color-grey-4: #495057;
  --color-grey-3: #6e7681;
  --color-grey-medium: #d3d7db; /* TODO find better naming for this one */
  --color-grey-2: #dee6eb;
  --color-grey-1: #f8f9fc;
  --color-white: #ffffff;

  /* Primary */
  --color-primary-eggplant: #4a4177;
  --color-primary-eggplant-dark: #3a3264;
  --color-primary-eggplant-medium: #a5a0bb;
  --color-primary-eggplant-light: #edecf1;
  --color-primary-peacock: #018e91;
  --color-primary-peacock-dark: #017c7e;
  --color-primary-peacock-darker: #016366;
  --color-primary-peacock-medium: #80c6c8;
  --color-primary-peacock-light: #e6f4f4;
  --color-primary-coral: #e0311a;
  --color-primary-coral-dark: #c91703;
  --color-primary-coral-medium: #f8aea4;
  --color-primary-coral-light: #feefed;

  /* Secondary */
  --color-secondary-pumpkin: #fb7e21;
  --color-secondary-pumpkin-medium: #fed8bc;
  --color-secondary-pumpkin-light: #fff2e9;
  --color-secondary-grass: #38976a;
  --color-secondary-grass-light: #ebf5f0;
  --color-secondary-sky: #365c94;
  --color-secondary-sky-light: #ebeff4;
  --color-secondary-sand: #f4b96d;

  --color-rippling-yellow: #fdb71c;
  --color-rippling-yellow-light: #fff4dd;

  /* Gradients */
  --color-gradient-eggplant: linear-gradient(90deg, #867daf 0%, #382c71 100%);
  --color-gradient-peacock: linear-gradient(90deg, #42c6ca -3.33%, #037577 100%);
  --color-gradient-coral: linear-gradient(90deg, #ff8d7e 0%, #db2e1c 100%);
  --color-gradient-pumpkin: linear-gradient(90deg, #ff9e45 0%, #db5d00 100%);
  --color-gradient-grass: linear-gradient(90deg, #56ce95 0%, #027c42 100%);
  --color-gradient-sky: linear-gradient(90deg, #80a2d5 -3.33%, #1e4c8f 100%);
  --color-gradient-sand: linear-gradient(90deg, #ffd687 0%, #de9638 100%);

  /* Typography
     ================================================================= */

  /* Font sizes */
  --type-body-xxs10: 10px;
  --type-body-xs12: 12px;
  --type-body-s14: 14px;
  --type-body-base16: 16px;
  --type-body-lrg18: 18px;
  --type-heading-s20: 20px;
  --type-heading-m24: 24px;
  --type-heading-lrg32: 32px;
  --type-heading-xl40: 40px;
  --type-heading-xxl48: 48px;

  /* Spacing */

  --spacing-0: 0px;
  --spacing-px: 1px;
  --spacing-eighth: 2px;
  --spacing-quarter: 4px;
  --spacing-three-eighth: 6px;
  --spacing-half: 8px;
  --spacing-five-eighth: 10px;
  --spacing-three-quarter: 12px;
  --spacing-one: 16px;
  --spacing-one-and-half: 24px;
  --spacing-one-and-three-quarter: 28px;
  --spacing-two: 32px;
  --spacing-two-and-quarter: 36px;
  --spacing-two-and-half: 40px;
  --spacing-three: 48px;
  --spacing-four: 64px;
}
