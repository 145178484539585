@import '~constants/colors';

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $primaryPeacock;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $primaryPeacock, 0 0 5px $primaryPeacock;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}
