@mixin font-b1-regular {
  font-family: var(--t-font-nunito);
  font-weight: var(--t-font-weight-regular);
  font-size: var(--t-font-size-primary);
  line-height: var(--t-font-line-primary);
}

@mixin font-b2-medium {
  @include font-b1-regular;
  font-weight: var(--t-font-weight-medium);
}

@mixin font-b2-small {
  @include font-b2-medium;
  font-size: var(--t-font-size-small);
}

@mixin font-heading-2 {
  // assuming that heading-1 will be a page heading
  font-family: var(--t-font-poppins);
  font-weight: var(--t-font-weight-regular);
  font-size: var(--type-heading-m24);
  line-height: var(--t-font-line-height-base);
}
