@import '~constants/colors';

.rc-dropdown-menu {
  font-family: 'Nunito', sans-serif;

  > .rc-dropdown-menu-item {
    font-size: var(--type-body-base16);
    color: $black;
    padding: 11px 15px;

    &:hover {
      background-color: $background;
      cursor: pointer;
    }
  }

  > .rc-dropdown-menu-item-disabled {
    opacity: 0.5;
  }
}
