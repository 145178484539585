@import '~constants/colors';
@import '~constants/breakpoints';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

.font-size--small {
  font-size: var(--font-size--small);
}

.font-size--default {
  font-size: var(--font-size--default);
}

.font-size--large {
  font-size: var(--font-size--large);
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  overflow-y: visible;
  height: 100%;
  color: $black;
  background-color: #fff;
  // enables print styles to properly apply background-color attribute
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  width: 100vw;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Nunito', sans-serif;
}

a {
  color: $peacockDark;
  text-decoration: none;
  &:hover {
    color: $peacockDarker;
  }
}

hr {
  border-top: 1px solid $primaryPeacock;
  border-bottom: 0;
  margin: 24px 0;
}

.grabbing {
  cursor: grabbing;
}

input[type='checkbox'].account-checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
}

.react-calendar {
  width: auto;
}

.react-calendar__navigation__label {
  display: flex;
  justify-content: space-around;
  &:hover {
    background-color: transparent !important;
  }
}

@media screen and (max-width: $sm) {
  .react-calendar__navigation button {
    min-width: auto;
  }
}

.react-calendar__navigation__label__divider {
  display: none;
}

.react-calendar__navigation button:enabled:hover {
  background: transparent !important;
}

.react-calendar__navigation button:enabled:focus {
  background: transparent !important;
}

.react-calendar__tile--hasActive {
  background-color: var(--color-secondary-sky-light);
}

.react-calendar__tile--hasActive abbr {
  color: var(--color-secondary-sky);
}

abbr {
  font-size: var(--type-body-xs12);
  font-family: var(--t-font-nunito);
  font-weight: var(--t-font-weight-regular);
  text-decoration: none;
  text-transform: none;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 25px;
}

.react-calendar__tile {
  margin: 2px 0 !important;
}

.react-calendar__tile.react-calendar__tile--hover {
  background-color: transparent;
}

.react-calendar__tile.react-calendar__tile--now {
  background: transparent;
}

.react-calendar__tile.react-calendar__tile--now abbr {
  color: var(--color-black);
}

.react-calendar__tile:enabled:hover {
  background-color: var(--color-secondary-sky-light) !important;
  abbr {
    color: var(--color-secondary-sky) !important;
  }
}
.react-calendar__tile:enabled abbr:hover {
  color: var(--color-secondary-sky) !important;
}

.react-calendar__tile--active {
  background: transparent;
}

.react-calendar__tile--hasActive:hover {
  abbr {
    color: var(--color-secondary-sky) !important;
  }
}

.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active:enabled:active,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: var(--color-secondary-sky-light) !important;
}

.react-calendar__tile--active:enabled abbr:focus,
.react-calendar__tile--active:enabled abbr:active,
.react-calendar__tile--rangeStart abbr,
.react-calendar__tile--rangeEnd abbr,
.react-calendar__tile--hasActive abbr,
.react-calendar__tile--hasActive:enabled abbr:focus {
  color: var(--color-secondary-sky) !important;
}

.react-calendar__tile.react-calendar__month-view__days__day abbr {
  font-size: var(--font-size--small);
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background: transparent;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend abbr {
  color: var(--color-black);
}
.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

.react-calendar__navigation__label {
  cursor: auto !important;
}

@import '~react-calendar/dist/Calendar.css';

@function toRem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}
