@import '~constants/colors';

@mixin title {
  font-family: 'Poppins';
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--type-heading-m24);
  font-weight: 400;
  line-height: 36px;
}

// @TODO: this is not meant to be for all components, but we don't need 1 per file either
.backButton {
  margin-bottom: 16px;
}

.header {
  display: flex;
  height: auto;
  justify-content: space-between;
  padding-inline: var(--spacing-one-and-half);
  width: 100%;
  &.noPadding {
    padding-inline: 0;
  }
  .titleContainer {
    display: flex;

    .title {
      @include title;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .planTitleContainer {
    display: flex;
    flex-direction: column;
    .title {
      @include title;
      margin: 0;
      margin-right: 8px;
    }
    .titleBlock {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .jobTitle {
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
      font-size: var(--type-body-xs12);
      line-height: 18px;
      color: $tableHeaderGrey;
      padding-right: 8px;
    }
  }

  .btnsContainer {
    display: inline-flex;
    & > * {
      margin-left: 16px;
      height: 45px;
    }
  }
}
